import React, { useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import ReactDOMServer from 'react-dom/server';
import get from 'lodash.get';
import { load } from 'cheerio';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';
import Breadcrumbs from '../components/blog/breadcrumbs';
import PostsSlider from '../components/common/Posts-slider';
import { getBreadcrumbList, resolveBlogCategorySlug, slugify } from '../utils';
import ShareIcons from '../components/blog/Share-icons';
import BlogPostMeta from '../components/blog/blog-post-meta';
import ConversionDeck from '../components/blog/conversion-deck';
import FollowBanner from '../components/blog/follow-banner';
import AuthorSocialIcons from '../components/blog/author-social-icons';
import AuthorDescription from '../components/blog/author-description';

function RenderContent({ content }) {
  const conversionDeckString = ReactDOMServer.renderToString(<ConversionDeck />);

  const contentWithConversionDeck = content.replaceAll(
    '[conversion-deck]',
    conversionDeckString,
  );

  const contentWithFluidImages = contentWithConversionDeck.replaceAll(
    '<img',
    '<img class="img-fluid"',
  );

  const contentWithCorrectUrls = contentWithFluidImages.replaceAll(
    'http://wordpress-388675-1222025.cloudwaysapps.com/wp-content',
    'https://static.pandevlaw.com/wp-content',
  );

  // Load content into Cheerio for manipulation
  const $ = load(contentWithCorrectUrls);
  // Add IDs to h2 tags
  $('h2').each((_, element) => {
    let id = '';
    const child = element.children[0];
    const grandChild = child.children;
    if (grandChild !== undefined) {
      const [heading] = grandChild;
      id = heading.data;
    } else {
      id = child.data;
    }

    $(element).attr('id', slugify(id)); // Add ID attribute to h2 tag
  });

  // Get HTML content with IDs added
  const modifiedContent = $.html();

  // Render modified content
  return (
    <div className="blog-content" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
  );
}

function BlogPost(props) {
  // eslint-disable-next-line no-unused-vars
  const { data, location, pageContext } = props;

  const blogPostContentRef = useRef(null);

  const { title, content, featuredImage, date, categories, author, tags, seo } =
    data.allWpPost.edges[0].node;

  // eslint-disable-next-line no-unused-vars
  const { relatedPosts, categories: blogCategories } = data;
  const headMeta = {
    title: get(seo, 'title', null),
    description: get(seo, 'metaDesc', null),
    keywords: get(seo, 'metaKeywords', null),
    robotsIndex: get(seo, 'metaRobotsNoindex', null),
    robotsFollow: get(seo, 'metaRobotsNofollow', null),
    image: get(featuredImage, 'node.sourceUrl', null),
  };

  const isFaq =
    location.pathname ===
    '/blog/opt-90-rule-unemployed-rule-employment-based-immigration/';

  const schema = `
	[{
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"headline": ${JSON.stringify(seo.title)},
		"description": ${JSON.stringify(seo.metaDesc)},
		"image": ${JSON.stringify(get(featuredImage, 'node.sourceUrl', null))},
		"author": {
			"@type": "Person",
			"name": ${JSON.stringify(author.node.name)},
      "url": "https://www.pandevlaw.com/blog/author/adrian/"
		},
		"publisher": {
			"@type": "Organization",
			"name": "PandevLaw",
			"logo": {
				"@type": "ImageObject",
				"url": "https://pandevlaw.com/img/logo.png"
			}
		},
		"datePublished": ${JSON.stringify(date)}
	},
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": ${getBreadcrumbList(location)}
  }]
	`;

  const faqSchema = `
  [
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": ${JSON.stringify(seo.title)},
      "description": ${JSON.stringify(seo.metaDesc)},
      "image": ${JSON.stringify(get(featuredImage, 'node.sourceUrl', null))},
      "author": {
        "@type": "Person",
        "name": ${JSON.stringify(author.node.name)},
        "url": "https://www.pandevlaw.com/blog/author/adrian/"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PandevLaw",
        "logo": {
          "@type": "ImageObject",
          "url": "https://pandevlaw.com/img/logo.png"
        }
      },
      "datePublished": ${JSON.stringify(date)}
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": ${getBreadcrumbList(location)}
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "OPT 90 Days Rule - How to Avoid Violating 90 Day Unemployment Rule",
      "description": "1,788 views  4 Aug 2022
      In this video, I'm going to show you the OPT 90-Day rule, and how to avoid violating the rule.
      
      If you want to read more about the OPT 90 days unemployment rule go to: https://www.pandevlaw.com/blog/opt-90...
      
      TIMESTAMPS
      00:00 Introduction - OPT 90-Day Rule
      00:10 Maximum 90 Days of Unemployment
      00:27 Counted in Aggregate 
      01:14 Multiple Types of Valid Employment
      01:49 Make Sure to Report
      02:13 Plan Ahead
      
      ⏰ Schedule a one-on-one consultation with me 
      https://www.pandevlaw.com/contact/
      
      --  Related F-1 OPT Videos --
      📺 Can I be Self-Employed While on F-1 OPT? -   
      
      • Can I be Self-Employed While on F-1  ...  
      📺 F-1 STEM OPT Self-Employed -   
      
      • F-1 STEM OPT Self-Employed - Things Y...  
      📺 OPT Reporting - It's Critical to Report Your Employment -   
      
      • OPT Reporting - It's Critical to Repo...  
      📺 STEM OPT 150 Days Unemployment - Explained! -   
      
      • STEM OPT 150 Days Unemployment - Expl...  
      📺 Types of OPT Employment - What Types of Employment Are Allowed Under OPT? -   
      
      • Types of OPT Employment - What Types ...  
      📺 E-verification Critical STEM OPT - Why is it Important? -   
      
      • E-verification Critical STEM OPT -  W...  
      📺 F-1 OPT vs. F-1 STEM OPT Employment -   
      
      • F-1 OPT vs.  F-1 STEM OPT Employment  
      
      --------- 💗 Let's Connect on Social Media ---------
      🏄 Facebook:  
      
      / pandevlaw  
      📸 Instagram:  
      
      / pandevlaw  
      👓 Twitter:  
      
      / pandevlaw  
      👔 LinkedIn:  
      
      / pand.  .
      
      --  📜 About Adrian Pandev --
      Adrian is regarded as a go-to attorney for foreign companies and individuals seeking to enter the US market. Adrian has extensive experience advising clients on cross-border transactions, structuring inbound investments, and pre-immigration planning.
      
      Adrian’s immigration practice focuses on investor and employment-based immigration, particularly E-2 Treaty Investors, EB-5 Investors, L-1 Intracompany Transferees, and H-1B Specialty Workers. Adrian has many years of experience with the family-based and employment-based permanent residence process, including PERM Labor Certifications, EB-2 National Interest Waivers, and EB-1 petitions for Multinational Managers and Individuals of Extraordinary Ability.
      
      Adrian represents startup founders, emerging and high-growth companies, venture capital and private equity funds, and strategic investors in various corporate transactions. He has extensive experience in company formation, corporate governance, growth equity and venture capital financing, mergers & acquisition, and fund formation. 
      
      Adrian represents individuals and families in developing wealth management structures which align with their multi-jurisdictional tax and legal needs. He has extensive experience with pre-immigration and expatriation planning.
      
      Prior to founding Pandev Law, Adrian served as a Trial Attorney at the U.S. Department of Justice in Washington, D.C.  Adrian teaches business law as an adjunct professor at St. John’s University in New York, NY.  He appears as a legal commentator on various news broadcasts, including ABC and NY1.
      
      -- ⛔ Disclaimer  ⛔ --
      Not Legal Advice. This video is provided for general educational and informational purposes only. Video does not establish an attorney-client relationship. No viewer should act or refrain from acting on the basis of information presented in this video without seeking the advice of appropriate legal or professional counsel. Attorney advertising. Prior results do not guarantee a similar outcome.",
      "thumbnailUrl": "https://img.youtube.com/vi/uPZn0Tb3YNU/maxresdefault.jpg",
      "uploadDate": "2022-04-08",
      "duration": "PT3M2S",
      "contentUrl": "https://www.youtube.com/watch?v=uPZn0Tb3YNU",
      "embedUrl": "https://www.youtube.com/embed/uPZn0Tb3YNU?si=JYgeLuDnlnHp9HT0"
  },
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is Optional Practical Training (OPT)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Optional Practical Training (OPT) is work authorization for F-1 Students. An F-1 student with valid post-completion OPT work authorization cannot accrue more than 90 days of unemployment. The OPT 90 days unemployment are calculated in the aggregate, meaning that an F-1 student with valid OPT work authorization who has accrued 30 days of unemployment may only be unemployed for an additional 60 days before violating his or her immigration status regarding OPT unemployment 90 days."
      }
    },{
      "@type": "Question",
      "name": "How long can students stay unemployed on OPT?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Students with a valid 24-month STEM OPT extension are granted 150 days of unemployment during their 36 months of OPT work authorization instead of the OPT rule 90 days. As an employment-based immigration lawyer, I have successfully represented numerous F-1 students in changing status from F-1 to H-1B and other employment-based immigration visas."
      }
    },{
      "@type": "Question",
      "name": "How can students avoid losing my OPT due to the OPT 90 days unemployment rule?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "US Citizenship and Immigration Services (USCIS) defines employment in broad terms. Generally, employment is defined as a paid or unpaid activity in the student’s field of study in which the student engages for at least 20 hours per week. Activities considered employment by USCIS that will prevent violation of the 90 days OPT unemployment rule include:  
        Standard employer-employee relationship with US employer
        Working for several employers
        Independent contractor
        Working for employer through third party agency
        Paid or unpaid internship
        Volunteering
        Self-employment"
      }
    },{
      "@type": "Question",
      "name": "Who should notify students to remain in compliance with OPT 90 days unemployment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You must notify the US Department of Homeland Security of any changes to your employment while on OPT through the SEVIS and SEVP Portals this helps to verify compliance with the OPT unemployment 90 days rule. I highly recommend you contact your Designated School Official with any questions on how to report your employment while on OPT to track work and meet the OPT rule 90 days. As an immigration lawyer, I have seen many international students who inadvertently violated their status by not following relevant immigration rules and therefore not complying with the 90 days OPT unemployment rule."
      }
    },{
      "@type": "Question",
      "name": "What happens if students violate the OPT 90 days unemployment rule?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Exceeding the OPT 90 days unemployment would mean you are not in compliance with the 90 days OPT unemployment rule. This may have serious immigration consequences. You may lose your immigration status, become ineligible to change your status to other employment-based immigration categories, such as H-1B status, or be denied visa applications in the future for failing to abide by the OPT rule 90 days."
      }
    },{
      "@type": "Question",
      "name": "Have you considered starting your own business on OPT and how it can help with the OPT unemployment 90 days maximum?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "F-1 students with valid OPT work authorization may start their own business and work for themselves. If you have a business idea that is related to your field of study, this may be a great opportunity to do so and it may also help with the OPT rule 90 days. Please note, while self-employment on OPT can stop you from violating the OPT 90 days unemployment rule, it is important that you comply with all immigration regulations when organizing and working for your own business. Please read our article on self-employment while on OPT for more information."
      }
    }]
  }
  ]`;

  const category = categories.nodes[0];

  const $ = load(content);
  const h2Elements = $('h2');

  // Generate table of contents structure
  const tableOfContents = h2Elements.map((index, element) => {
    const pattern = /^\d+\.\s*/;
    const text = $(element).text().replace(pattern, '');
    const id = $(element).attr('id') || `section-${index + 1}`; // Use id attribute if present, otherwise generate one
    return (
      <li key={id}>
        <AnchorLink to={`#${slugify(text)}`} title="Our team">
          <span>
            {index + 1}. {text}
          </span>
        </AnchorLink>
      </li>
    );
  });

  return (
    <Layout
      location={location}
      headMeta={headMeta}
      footerSchema={isFaq ? faqSchema : schema}>
      <div className="blog">
        <div className="container blog-container-smaller blog-post">
          <Breadcrumbs
            currentPage={title}
            categoryName={category.name}
            categorySlug={category.slug}
          />
          <h1 className="mb-4 text-hero">{title}</h1>
        </div>
        <div className="container blog-post">
          {featuredImage?.node?.localFile && (
            <GatsbyImage
              image={getImage(featuredImage.node.localFile)}
              className="img-fluid blog-post-image"
              alt={title}
              loading="eager"
            />
          )}
        </div>
        <div
          className="container blog-container-smaller blog-post pt-5"
          ref={blogPostContentRef}>
          <FollowBanner blogPostContentRef={blogPostContentRef} />
          <BlogPostMeta date={date} author={author} tags={tags} />
          {tableOfContents.length > 1 && (
            <nav className="toc">
              <h3>Table of Contents</h3>
              <ul>{tableOfContents}</ul>
            </nav>
          )}
          <RenderContent content={content} />
          <ShareIcons pathname={location.pathname} />
          <div className="d-block d-md-flex justify-content-start mt-9">
            <div className="mr-5 text-center">
              <Link to="/blog/author/adrian">
                <StaticImage
                  className="mr-3 photo-rounded blog-post-author-photo"
                  src="../assets/img/pandev-photo.jpg"
                  width={300}
                  height={300}
                  alt="Adrian Pandev Photo"
                />
              </Link>
            </div>

            <div>
              <h3 className="text-bold text-dark mb-3">
                <Link to="/blog/author/adrian">Adrian Pandev</Link>
              </h3>
              <p className="text-lead text-grey mb-3">
                <AuthorDescription />
              </p>

              <AuthorSocialIcons />
            </div>
          </div>
        </div>

        <CtaDeck />

        <PostsSlider
          title={`Explore more of ${category.name.toLowerCase()}`}
          posts={relatedPosts}
          infinite
          seeMoreLink={resolveBlogCategorySlug(category.slug)}
          seeMoreText={`See all ${category.name.toLowerCase()} posts`}
        />
      </div>
    </Layout>
  );
}

BlogPost.propTypes = {};

export const query = graphql`
  query BlogPostsQuery($id: String, $categoryId: Int) {
    allWpPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          date
          author {
            node {
              name
            }
          }
          seo {
            title
            metaDesc
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
          }
          tags {
            nodes {
              id
              uri
              name
              slug
            }
          }
          categories {
            nodes {
              id
              name
              slug
              uri
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    breakpoints: [750, 1080, 1366, 1920]
                    backgroundColor: "transparent"
                  )
                }
              }
              altText
              sourceUrl
            }
          }
        }
      }
    }
    relatedPosts: allWpPost(
      limit: 9
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: $categoryId } } } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
  }
`;

export default BlogPost;
